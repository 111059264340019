import { gsap } from "gsap";
import { TextPlugin } from "gsap/TextPlugin";
import Splitting from "splitting";

// Settings Pane for Design
const PANE_PARAMS = {
  logoLetters: 0.085,
  textLetters: 0.1,
  wordDelay: 0.1,
};

gsap.registerPlugin(TextPlugin);

import AudioPlayer from "./audio-player";
const audioPlayer = new AudioPlayer();

const soundButtonEl = document.querySelector(".js-sound");
const contentEl = document.querySelector<HTMLDivElement>(".js-content");
const aboutEls = document.querySelectorAll(".js-about-line");
const logoEl = document.querySelector(".js-logo");
const logoButtonEl = document.querySelector(".js-button");
const logoLettersEl = logoEl.querySelectorAll(".js-letters path");

aboutEls.forEach((lineEl) => {
  Splitting({ target: lineEl, by: "words" });
});

contentEl.classList.add("show");

let timeline: gsap.core.Timeline;

function setupTimeline() {
  createTimeline();

  soundButtonEl.addEventListener("click", () => {
    if (audioPlayer.muted) {
      const offset = !timeline.paused() ? timeline.time() : 0;
      audioPlayer.unmute(offset);
      soundButtonEl.classList.add("active");
    } else {
      audioPlayer.mute();
      soundButtonEl.classList.remove("active");
    }
  });

  // console.log(timeline.duration());

  logoButtonEl.addEventListener("click", () => {
    gsap.to(logoLettersEl, {
      opacity: 0,
      duration: 0.28,
      onComplete: () => {
        timeline.restart();
        soundButtonEl.classList.add("active");
        audioPlayer.unmute(0, true);
      },
    });
  });
}

function createTimeline() {
  timeline = gsap.timeline({
    delay: 0.1,
    onComplete: () => {
      logoButtonEl.classList.add("title__button--active");
    },
  });
  timeline.to(logoLettersEl, { opacity: 0, duration: 0 });
  timeline.to(logoLettersEl, {
    opacity: 1,
    duration: 0,
    stagger: PANE_PARAMS.logoLetters,
    yoyo: true,
    repeat: 1,
    repeatDelay: 1,
  });

  aboutEls.forEach((lineEl, index) => {
    // const duration = line.length * 0.07145;
    // const duration = line.length * 0.4;
    lineEl
      .querySelectorAll<HTMLSpanElement>(".word, .whitespace")
      .forEach((el, wordIndex) => {
        const isWhitespace = el.classList.contains("whitespace");
        if (isWhitespace) {
          timeline.to(el, {
            opacity: 1,
            duration: 0,
            // delay: 0.07145,
            delay: PANE_PARAMS.wordDelay,
          });
        } else {
          const delay = index === 0 && wordIndex === 0 ? 0.333 : 0;
          let text: string;
          if (el.dataset.content) {
            text = el.dataset.content;
          } else {
            text = el.textContent;
            el.style.width = `${el.getBoundingClientRect().width}px`;
            el.dataset.content = text;
            el.textContent = "";
          }
          const duration = text.length * PANE_PARAMS.textLetters;
          timeline.to(el, { opacity: 1, text: "", duration: 0, delay: delay });
          timeline.to(el, {
            duration,
            text,
            ease: "none",
          });
        }
      });
    timeline.to(lineEl, { opacity: 0, duration: 0.28, delay: 1 });
  });

  timeline.to(logoEl.querySelectorAll("path"), {
    opacity: 1,
    duration: 0,
    stagger: PANE_PARAMS.logoLetters,
  });
  audioPlayer.play();
}

async function init() {
  await document.fonts.ready;
  setupTimeline();
}

init();
