const track = require("url:../sounds/formation-2.m4a");

class AudioPlayer {
  audioObj: HTMLAudioElement;
  delay = undefined;

  constructor() {
    this.audioObj = new Audio(track);
    this.audioObj.muted = true;
  }

  play = (offset = 0, forceRestart = false) => {
    // console.log({ forceRestart });
    const baseOffset = -6.3;
    const totalOffset = baseOffset + offset;
    clearTimeout(this.delay);
    const time = forceRestart ? 0 : this.audioObj.currentTime;
    if (time + totalOffset < 0) {
      if (forceRestart) this.audioObj.pause();
      const diff = time + totalOffset;
      // console.log("Playing from 0 with delay", Math.abs(diff) * 1000);
      this.delay = setTimeout(() => {
        // console.log("play after delay");
        this.audioObj.play();
        this.audioObj.currentTime = 0;
      }, Math.abs(diff) * 1000);
    } else {
      // console.log("Playing", totalOffset);
      this.audioObj.play();
      this.audioObj.currentTime = 0 + baseOffset + offset;
    }
  };

  mute = () => {
    this.audioObj.muted = true;
  };

  unmute = (offset = 0, forceRestart = false) => {
    this.audioObj.muted = false;
    if (forceRestart) this.play(offset, true);
    if (!forceRestart && this.audioObj.paused) this.play(offset);
  };

  get muted() {
    return this.audioObj.muted;
  }
}

export default AudioPlayer;
